import { EmailList } from "../EmailList";
import { EntityMerge } from "../EntityMerge";
import { HeaderGoBack, KeyboardAvoidingView } from "@src/components";
import { MultiFactorAuthentication } from "../MultiFactorAuthentication";
import { Preferences } from "../Preferences";
import { ProfileImage } from "../ProfileImage";
import { ScrollView, StyleSheet, View } from "react-native";
import { msg } from "@lingui/macro";
import { useFeatureFlags, useGetTestProps } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSettings } from "./hooks";
import { useTheme } from "@merit/frontend-components";
import type { SettingsProps } from "./types";
import type { ViewStyle } from "react-native";

const Settings = ({}: SettingsProps) => {
  const { bottom } = useSafeAreaInsets();
  const getTestProps = useGetTestProps();
  const { theme } = useTheme();
  const useSettingsValues = useSettings();
  const { _ } = useLingui();
  const featureFlags = useFeatureFlags();

  if (useSettingsValues === undefined) {
    return null;
  }

  const { displayName, emails, legalName } = useSettingsValues;

  const styles = StyleSheet.create<{
    readonly mainContentContainer: ViewStyle;
    readonly mainScrollView: ViewStyle;
    readonly screenContainer: ViewStyle;
    readonly sectionSpacer: ViewStyle;
  }>({
    mainContentContainer: {
      paddingBottom: Math.max(bottom, theme.spacing.xl),
      paddingHorizontal: theme.spacing.xxl,
    },
    mainScrollView: {
      flex: 1,
    },
    screenContainer: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },
    sectionSpacer: {
      marginBottom: theme.spacing.xxl,
    },
  });

  return (
    <KeyboardAvoidingView
      {...getTestProps({
        elementName: "Settings",
      })}
      style={styles.screenContainer}
    >
      <HeaderGoBack title={_(msg({ context: "title", message: "Settings" }))} />
      <ScrollView contentContainerStyle={styles.mainContentContainer} style={styles.mainScrollView}>
        <ProfileImage displayName={displayName} legalName={legalName} />
        <EmailList emails={emails} />
        <View style={styles.sectionSpacer} />
        <EntityMerge />
        {featureFlags.showMemberAppSettingsMFA === true && (
          <>
            <View style={styles.sectionSpacer} />
            <MultiFactorAuthentication />
          </>
        )}
        <View style={styles.sectionSpacer} />
        <Preferences />
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export { Settings };
