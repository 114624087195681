import { Log } from "@src/utils";
import { msg } from "@lingui/macro";
import { useAlerts, useFeatureFlags, useLogoutOnError } from "@src/hooks";
import { useEffect } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useLingui } from "@lingui/react";
import { useShareMeritsApi } from "./useShareMeritsApi";
import type { SharePagesResponse } from "@merit/share-merits-client";

const LIMIT = 50;

type QueryKey = readonly ["getShares"];

export const useGetSharesQueryKey = (): QueryKey => ["getShares"];

export const useGetShares = () => {
  const { api: shareMeritsApi, isReady } = useShareMeritsApi();
  const { showMemberAppShareMeritsFeature } = useFeatureFlags();
  const { onError } = useLogoutOnError();
  const queryKey = useGetSharesQueryKey();
  const { sendAlert } = useAlerts();
  const { _ } = useLingui();

  const result = useInfiniteQuery({
    enabled: isReady && showMemberAppShareMeritsFeature === true,
    getNextPageParam: (lastPage: SharePagesResponse, allPages: readonly SharePagesResponse[]) => {
      if (lastPage.hasMore === true) {
        return allPages.length * LIMIT;
      }

      return undefined;
    },
    initialPageParam: 0,
    queryFn: ({ pageParam = 0 }) => shareMeritsApi.getShares({ limit: LIMIT, offset: pageParam }),
    queryKey,
  });

  useEffect(() => {
    if (result.isError) {
      Log.error(`Error getting shared links`, { error: result.error });
      sendAlert({
        id: "getShares-error",
        text1: _(msg`Error getting shared links`),
        text2: String(result.error),
        type: "error",
      });
      onError(result.error);
    }
  }, [result.isError, result.error, onError, sendAlert, _]);

  return result;
};
